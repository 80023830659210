<template>
  <IAMTable
    :columns="columns"
    :endpoint="endpoint"
    :selectable="false"
    :actions="actions"
    @detail="detail"
    @edit="edit"
    @reset="reset"
    @trash="trash"
    ref="invoiceTable"
  ></IAMTable>
</template>

<script>
import Vue from 'vue'
import IAMTable from '@/components/iam/table/Table'

Vue.component('invoice-supplier', {
  props: ['value', 'object'],
  template: '<span>{{ object.supplier.name }}</span>'
})

Vue.component('invoice-amount', {
  props: ['value', 'object'],
  template: '<span>&euro; {{ object.amount|currency }}</span>'
})

Vue.component('invoice-env-contribution', {
  props: ['value', 'object'],
  template: '<span>&euro; {{ object.environment_contribution|currency }}</span>'
})

Vue.component('invoice-balance', {
  props: ['value', 'object'],
  template: '<span>&euro; {{ object.balance|currency }}</span>'
})

export default {
  name: 'InvoiceTable',
  components: { IAMTable },
  data () {
    return {
      id: 'InvoiceList',
      endpoint: '/api/budget/invoices',
      columns: [
        { title: 'Nr', key: 'number' },
        { title: 'Datum', key: 'date' },
        { title: 'Leverancier', renderComponent: 'invoice-supplier' },
        { title: 'Bedrag', renderComponent: 'invoice-amount' },
        { title: 'Milieubijdrage', renderComponent: 'invoice-env-contribution' },
        { title: 'Saldo', renderComponent: 'invoice-balance' }
      ],
      actions: [
        {
          key: 'detail',
          type: 'default',
          icon: 'search',
          title: 'Details'
        },
        {
          key: 'edit',
          type: 'default',
          icon: 'pencil',
          title: 'Bewerken'
        },
        {
          key: 'reset',
          type: 'default',
          icon: 'refresh',
          title: 'Resetten'
        },
        {
          key: 'trash',
          type: 'default',
          icon: 'trash',
          title: 'Verwijderen'
        }
      ]
    }
  },
  methods: {
    detail (value) {
      this.$emit('detail', value)
    },
    edit (value) {
      this.$emit('edit', value)
    },
    reset (value) {
      this.$emit('reset', value)
    },
    trash (value) {
      this.$emit('trash', value)
    },
    refresh () {
      this.$refs.invoiceTable.refresh()
    }
  }
}
</script>

<style scoped>

</style>
