<template>
  <Modal :title="title" ref="modal">
    <div slot="footer">
      <a @click="saveInvoiceHandler" class="btn btn-primary" target="_blank" type="button">Opslaan</a>
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Sluiten</a>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-2 control-label">Factuur nr</label>
          <div class="col-sm-10">
            <input class="form-control" placeholder="Uniek nr van de factuur" ref="numberInput"
                   type="text" v-model="number">
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Leverancier</label>
          <div class="col-sm-10">
            <select class="form-control" v-model="supplier">
              <option :key="supplier.id" :value="supplier" v-for="supplier in suppliers">{{ supplier.name }}</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Datum</label>
          <div class="col-sm-10">
            <DatePicker v-model="date"></DatePicker>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Bedrag</label>
          <div class="col-sm-10">
            <input class="form-control" placeholder="Bedrag van de factuur" type="text"
                   v-model="amount" v-on:keyup.enter="saveInvoiceHandler">
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Milieubijdrage</label>
          <div class="col-sm-10">
            <input class="form-control" placeholder="Bedrag van milieubijdrage" type="text"
                   v-model="environmentContribution" v-on:keyup.enter="saveInvoiceHandler">
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Opmerkingen</label>
          <div class="col-sm-10">
            <textarea class="form-control" cols="10" rows="4" v-model="description"></textarea>
          </div>
        </div>
      </div>
    </div>

  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'
import moment from 'moment'

import Modal from '../iam/Modal'
import DatePicker from '../iam/DatePicker'

export default {
  name: 'InvoiceModal',
  components: { DatePicker, Modal },
  mounted () {
    this.loadSuppliers()
  },
  data () {
    return {
      id: null,
      number: '',
      supplier: null,
      date: moment(),
      amount: '0',
      environmentContribution: '0',
      description: ''
    }
  },
  watch: {
    amount () {
      this.amount = this.amount.replace(',', '.')
    },
    environmentContribution () {
      if (typeof this.environmentContribution === 'string' || this.environmentContribution instanceof String) {
        this.environmentContribution = this.environmentContribution.replace(',', '.')
      }
    }
  },
  computed: {
    ...mapGetters('budget', ['getSuppliers']),
    title () {
      if (this.id) return 'Factuur bewerken'
      return 'Nieuwe factuur registreren'
    },
    suppliers () {
      return this.getSuppliers()
    }
  },
  methods: {
    ...mapActions('budget', ['loadSuppliers', 'loadFinancialReport', 'saveInvoice']),
    /**
       * Load an existing invoice into the modal
       * @param invoice
       */
    loadInvoice (invoice) {
      this.id = invoice.id
      this.number = invoice.number
      this.supplier = invoice.supplier
      this.date = moment(invoice.date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      this.amount = invoice.amount
      this.environmentContribution = invoice.environmentContribution || 0
      this.description = invoice.description
    },
    saveInvoiceErrorHandler () {
      return Promise.reject(new Error('Kan factuur niet aanmaken, gelive opnieuw te proberen'))
    },
    async handleSaveInvoiceSuccess () {
      this.$emit('invoice-saved')
      if (this.id) {
        this.hide()
      }
      this.id = null
      this.number = ''
      this.amount = '0'
      this.environmentContribution = '0'
      this.description = ''
      this.$refs.numberInput.focus()

      await this.loadFinancialReport().then(
        () => {
          stopLoadingModal()
          return Promise.resolve()
        },
        () => {
          return Promise.reject(new Error('Kan factuurgegevens niet herladen, gelieve de pagina manueel te herladen'))
        }
      )
    },
    async saveInvoiceHandler () {
      startLoadingModal('Nieuwe factuur aan het aanmaken')
      const data = {
        id: this.id,
        number: this.number,
        supplier: this.supplier.id,
        date: moment(this.date).format('YYYY-MM-DD'),
        amount: this.amount,
        environment_contribution: this.environmentContribution,
        description: this.description
      }

      await this.saveInvoice(data)
        .then(this.handleSaveInvoiceSuccess, this.saveInvoiceErrorHandler)
        .catch((e) => errorModal(e.message))
    },
    show () {
      this.$refs.modal.show()
      this.$refs.numberInput.focus()
    },
    hide () {
      this.$refs.modal.hide()

      this.id = null
      this.number = ''
      this.supplier = null
      this.date = moment()
      this.amount = '0'
      this.environmentContribution = '0'
      this.description = ''
    }
  }
}
</script>

<style scoped>

</style>
