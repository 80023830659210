<template>
  <Modal title="Factuur details" ref="modal">
    <div slot="footer">
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Sluiten</a>
    </div>

    <div v-if="invoice">
      <h3>Algemeen</h3>
      <div class="dl-horizontal">
        <dt>Factuur nr</dt>
        <dd>{{ invoice.number }}</dd>
        <dt>Leverancier</dt>
        <dd><span v-if="invoice.supplier">{{ invoice.supplier.name }}</span></dd>
        <dt>Datum</dt>
        <dd>{{ invoice.date|date-day}}</dd>
        <dt>Bedrag</dt>
        <dd>&euro; {{ invoice.amount|currency}}</dd>
        <dt>Milieubijdrage</dt>
        <dd>&euro; {{ invoice.environment_contribution|currency}}</dd>
        <dt>Saldo</dt>
        <dd>&euro; {{ invoice.balance|currency}}</dd>
      </div>
      <h3>Bestellingen</h3>
      <table class="table table-hover" v-if="invoiceDetails">
        <thead>
        <tr>
          <th>Kantoor</th>
          <th>Datum</th>
          <th>Product</th>
          <th>Bedrag</th>
          <th>Gefactureerd</th>
          <th>Afgesloten</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="order in invoiceDetails.orders" :key="order.id">
          <td>{{ order.order.office }}</td>
          <td>{{ order.order.order_date }}</td>
          <td>{{ order.order.product.name }}</td>
          <td>&euro; {{ order.order.amount|currency }}</td>
          <td>&euro; {{ order.order.invoiced_amount|currency }}</td>
          <td>
            <i class="glyphicon glyphicon-ok" v-if="order.order.closed"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </Modal>
</template>

<script>
import Modal from '../iam/Modal'
import $ from 'jquery'

export default {
  name: 'InvoiceDetailModal',
  components: { Modal },
  props: ['invoice'],
  data () {
    return {
      invoiceDetails: null
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  },
  watch: {
    invoice () {
      if (this.invoice) {
        $.ajax({
          url: `/api/budget/invoices/${this.invoice.id}`
        }).done((data) => {
          this.invoiceDetails = data
        }).fail((error) => {
          console.error(error)
        })
      }
    }
  }
}
</script>
