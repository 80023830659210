<template>
  <Page>
    <span slot="title">Facturen</span>
    <div slot="actionbar">
      <div class="btn-group">
        <a class="btn btn-default" href="/#/budget/book-invoices">
          <i class="glyphicon glyphicon-euro"></i> Inboeken
        </a>
        <a @click="showInvoiceModal" class="btn btn-default">
          <i class="glyphicon glyphicon-plus-sign"></i> Factuur
        </a>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-sm-4" v-if="report">
          Totaalbedrag ingeboekte facturen: &euro; {{ this.report.bookedTotal|currency }}
        </div>
        <div class="col-sm-4" v-if="report">
          Openstaand saldo: &euro; {{ this.report.balanceTotal|currency }}
        </div>
      </div>
      <InvoiceTable
        @detail="showInvoiceDetails"
        @edit="edit"
        @reset="reset"
        @trash="trash"
        ref="invoiceTable"></InvoiceTable>

      <InvoiceModal ref="invoiceModal" @invoice-saved="refreshTable"></InvoiceModal>
      <InvoiceDetailModal :invoice="selectedInvoice" ref="invoiceDetailModal"></InvoiceDetailModal>
    </div>
  </Page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Page from '@/components/iam/Page'
import InvoiceTable from '@/components/budget/InvoiceTable'
import InvoiceModal from '@/components/budget/InvoiceModal'
import InvoiceDetailModal from '@/components/budget/InvoiceDetailModal'
import { questionModal, startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'

export default {
  name: 'InvoiceList',
  components: { InvoiceDetailModal, InvoiceModal, Page, InvoiceTable },
  data () {
    return {
      selectedInvoice: null
    }
  },
  async created () {
    this.loadFinancialReport()
  },
  computed: {
    ...mapGetters('budget', ['getFinancialReport']),
    report () {
      return this.getFinancialReport()
    }
  },
  methods: {
    ...mapActions('budget', ['loadFinancialReport', 'resetInvoice', 'deleteInvoice']),

    handleResetError () {
      return Promise.reject(new Error('Kan factuur niet resetten gelieve opnieuw te proberen'))
    },
    handleDeleteInvoiceError () {
      return Promise.reject(new Error('Kan factuur niet verwijderen gelieve opnieuw te proberen'))
    },
    async handleInvoiceSuccess () {
      this.refreshTable()
      await this.loadFinancialReport().then(
        () => {
          stopLoadingModal()
          return Promise.resolve()
        },
        () => {
          return Promise.reject(new Error('Kan factuurgegevens niet herladen, gelieve de pagina manueel te herladen'))
        }
      )
    },
    showInvoiceModal () {
      this.$refs.invoiceModal.show()
    },
    showInvoiceDetails (invoice) {
      this.selectedInvoice = invoice
      this.$refs.invoiceDetailModal.show()
    },
    edit (invoice) {
      this.$refs.invoiceModal.loadInvoice(invoice)
      this.showInvoiceModal()
    },
    async reset (invoice) {
      const result = await questionModal('Weet je zeker dat je deze factuur wil resetten?')
      if (!result.value) return
      startLoadingModal('De factuur wordt gereset...')
      this.resetInvoice(invoice.id)
        .then(this.handleInvoiceSuccess, this.handleResetError)
        .catch((e) => errorModal(e.message))
    },
    async trash (invoice) {
      const result = await questionModal('Weet je zeker dat je deze factuur wil verwijderen?')
      if (!result.value) return
      startLoadingModal('Bezig met het verwijderen van deze factuur...')
      this.deleteInvoice(invoice.id)
        .then(this.handleInvoiceSuccess, this.handleDeleteInvoiceError)
        .catch((e) => errorModal(e.message))
    },
    refreshTable () {
      this.$refs.invoiceTable.refresh()
    }
  }
}
</script>
